import React, {createContext, useMemo} from "react";
import {useImmer} from "~hooks/useImmer";
import {Dispatch, State} from "~models/interfaces";

export const StateContext = createContext<State>(initializeState());
export const DispatchContext = createContext<Dispatch>(null);

export const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);

export const Provider = ({children}) => {
  const defaultState = useMemo(() => initializeState(), []);

  const [state, updateState] = useImmer({...defaultState});

  const defaultDispatch = useMemo(() => initializeDispatch(updateState), [updateState]);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={defaultDispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

function initializeState(): State {
  return {
    loading: true
  };
}

function initializeDispatch(updateState): Dispatch {
  return {};
}
