import React from "react";
import logo from "~assets/img/logo.png";
import appStoreDl from "~assets/img/app-store-dl.png";

export const PageMain = () => {
  return (
    <>
      <div className="page-home">
        <div className={"header-text"}>
          <span className="green">Welcome </span> to
          <span className="blue"> Hair Ninja!</span>
        </div>
        <div className="img-container">
          <img src={logo} alt="logo" className="logo-img" />
        </div>
        <div className="img-container">
          <img
            src={appStoreDl}
            alt="download on app store"
            className="dl-img"
            onClick={() => window.location.assign("https://apps.apple.com/us/app/id1538379980")}
          />
        </div>
      </div>
    </>
  );
};
