import React from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {Provider} from "~contexts";
import {PageMain} from "~pages/Main/PageMain";
import {PageTerms} from "~pages/policies/terms";
import {PagePrivacy} from "~pages/policies/privacy";
import "~assets/styles/root.scss";

export default () => {
  return (
    <Provider>
      <Routing/>
    </Provider>
  );
}

const Routing = () => {
  let location = useLocation();

  return (
    <>
      <Switch location={location}>
        <Route path={`/terms`}>
          <PageTerms/>
        </Route>
        <Route path={`/privacy`}>
          <PagePrivacy/>
        </Route>
        <Route path={`/`}>
          <PageMain/>
        </Route>
        <Redirect to="/"/>
      </Switch>
    </>
  );
};
